@charset "utf-8";

// bulma overrides
$text-light: hsl(0, 0%, 40%); // was previously too light
$turquoise: hsl(
  163%,
  68%,
  49%
); // our colour, but bumped lightness from 58% to 49% to make it invert to white text
$black: hsl(0, 0%, 4%);
$modal-background-background-color: rgba(
  $black,
  0.6
); // original of 0.86 was too dark
$input-border-color: #bbb; // was too dark otherwise

@import "~bulma/bulma.sass";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "./Spinner.scss";

body,
html {
  background-color: #fff;
  color: #000;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.3rem;
  margin-top: 0.3rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.public-only-container > div {
  margin: 0 auto;
}

h1 a {
  color: inherit;
}

.App {
  padding: 1.2rem;
}

img#logo {
  width: 256px;
  height: 74px;
}

#left-menu {
  padding: 1rem 1rem 2rem 2rem;
}

#left-menu .menu ul.menu-list > li span.icon {
  margin-right: 0.7rem;
}

.file-upload {
  height: 400px;
  text-align: center;
  margin: auto;
}

.calibration-link {
  overflow-wrap: anywhere;
}

#report-page {
  width: 900px;
}

#estimate-success {
  h3.operation-header:not(:first-child) {
    margin-top: 0;
  }

  h3 {
    // increase from 1.5em
    font-size: 1.7em;
  }

  .hover-image-table {
    display: table;
    color: #000;
    font-family: sans-serif;
    font-size: 18px;

    .hover-image-row {
      display: table-row;

      .hover-image-cell {
        display: table-cell;
        padding: 0 10px;
        max-width: 500px;
        background-color: #fff;
      }
    }

    // slightly hacky: do this vertical padding only for hover
    // images with a hover image cell
    .hover-image-row:first-child .hover-image-cell {
      padding-top: 10px;
    }

    .hover-image-row:last-child .hover-image-cell {
      padding-bottom: 10px;
    }
  }

  .report-matches {
    h3 {
      border-bottom: 2px solid #000;
      padding-bottom: 0.5rem;
    }

    .report-match:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

#past-reports-table {
  min-width: 620px;

  tbody > tr {
    cursor: pointer;
  }

  // date column
  tr > td:first-child,
  tr > th:first-child {
    width: 8rem;
    min-width: 7rem;
  }

  // VIN column
  tr > td:nth-child(2),
  tr > th:nth-child(2) {
    width: 11rem;
    min-width: 11rem;
  }

  // vehicle column
  tr > td:nth-child(3),
  tr > th:nth-child(3) {
    width: 12rem;
    min-width: 12rem;
    word-wrap: break-word;
  }

  // shop column
  tr > td.shop-column,
  tr > th.shop-column {
    width: 12rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // buttons column
  tr > td:last-child,
  tr > th:last-child {
    width: 16rem;
    min-width: 16rem;
  }
}

[data-tip="true"] {
  border-bottom: 1px dashed #dbdbdb;
}

.content table tbody td {
  vertical-align: middle;
}

label {
  margin-right: 10px;
}

#notification {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  &.shown {
    display: block;
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1;
    -moz-animation: fadeIn ease 1;
    -o-animation: fadeIn ease 1;
    -ms-animation: fadeIn ease 1;
  }
}

.field.is-grouped > .control > .checkbox {
  margin-top: 10px;
}

.message.is-warning .message-body {
  // was hard to read
  color: #4a4a4a !important;
}

.is-clickable {
  cursor: pointer !important;
}

#customerNameEdit {
  display: inline-block;
  margin-left: 0.2rem;

  .control {
    margin-bottom: 0rem;
  }
}

#plans {
  .selected-plan {
    border: 2px solid $primary;
  }
  .column > .box {
    height: 100%;
    display: flex;
    flex-direction: column;

    .plan-content {
      flex: 1;
      padding-bottom: 1rem;
    }

    .plan-price {
      font-size: 1.8rem;
    }

    .plan-yearly,
    .plan-monthly {
      position: relative;
    }

    .plan-yearly::after,
    .plan-monthly::after {
      position: absolute;
      bottom: 0;
      margin-left: 0.3rem;

      font-size: 1rem;
      font-weight: 700;
      color: #b9c2d2;
    }
    .plan-yearly::after {
      content: "/year";
    }
    .plan-monthly::after {
      content: "/mo";
    }
  }
}

.brand-color {
  color: $turquoise;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
